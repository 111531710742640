import "./App.css";
import { Routes, Route } from "react-router-dom";
import PageBody from "Theme/PageBody";
import HomePage from "Pages/Home";
import FormPage from "Pages/Form";
import LoginPage from "Pages/Login";
import RegisterPage from "Pages/Register";
import Portal from "Portal"

function App() {
 
  return (
    <PageBody>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/app" element={<Portal />} >
          <Route path="login" element={<LoginPage />} />
          <Route path="form" element={<FormPage />} />
          <Route path="register" element={<RegisterPage />} />
        </Route>
       </Routes>
    </PageBody>
  );
}

export default App;
