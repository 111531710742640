import SubTitle from "Theme/SubTitle";
import ImageTable from "./partials/ImageTable";


const DiskImage = ({computer}) => {

  return (
    <>
      <SubTitle>Obrazy dysku</SubTitle>
      <ImageTable files={computer.images} />
      
    </>
  );
};

export default DiskImage;
