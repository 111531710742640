import { useState } from "react";
import SubTitle from "Theme/SubTitle";

const TableHeader = ({title}) => (
<thead className="text-xs text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
                {title}
            </th>
          </tr>
        </thead>
)

const TableRow = ({name, value}) => {

    const formatValue = (value) => {
        return value
          ? value.split('\n').map((line, index) => (
              <p key={index}>
                {line}
              </p>
            ))
          : value;
      };

    return (
    <tr className="bg-white border-b  dark:border-gray-700">
                {name && (
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {name}
                </th>
                )}
                
                <td className="px-6 py-4">{formatValue(value)}</td>
              </tr>
)
}

const Table = ({children}) => (
    <table className="w-full text-sm text-left text-gray-500 mb-8">
        {children}
    </table>
)

const Config = ({config}) => {


  return (
    <>
       
      <SubTitle>Bieżąca konfiguracja</SubTitle> 

   
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
      
     
        <Table>
            <TableHeader title="Informacje o systemie" />
            <tbody>
            
                <TableRow name="Producent" value={config.manufacturer} />
                <TableRow name="Model" value={config.model} />
                <TableRow name="System operacyjny" value={config.systemoperacyjny} />
                <TableRow name="UUID" value={config.uuid} />

            </tbody>
        </Table>

        
       
        {config?.computer && (
            <Table>
                <TableHeader title="Komputer" />
                <tbody>
                    <TableRow  value={config.computer} />
                </tbody>
            </Table>
        )}
        

        {config?.plytaglowna && (
        <Table>
        <TableHeader title="Płyta główna" />
        <tbody>
          
             <TableRow name="Płyta główna" value={config.plytaglowna} />
             <TableRow name="BIOS" value={config.bios} />
        </tbody>
        </Table>
        )}

        {config?.listacpu && (
            <Table>
            <TableHeader title="Procesor" />
            <tbody>
            
                <TableRow  value={config.listacpu} />
            </tbody>
            </Table>
        )}

        {config?.listacpu && (
            <Table>
            <TableHeader title="Dyski twarde" />
            <tbody>
            
                <TableRow  value={config.listadyskow} />
            </tbody>
            </Table>
        )}


        {config?.pamiec && (    
            <Table>
                <TableHeader title="Pamięć RAM" />
                <tbody>
                
                    <TableRow  value={config.pamiec} />
                </tbody>
            </Table>
        )}

        {config?.kartysieciowe && (    
        <Table>
        <TableHeader title="Karty sieciowe" />
        <tbody>
          
             <TableRow  value={config.kartysieciowe} />
        </tbody>
        </Table>
       
        )}

        {config?.kartydzwiekowe && (    
            <Table>
                <TableHeader title="Karty dźwiękowe" />
                <tbody>
                
                    <TableRow  value={config.kartydzwiekowe} />
                </tbody>
            </Table>
        )}

        {config?.kartygraficzne && (    
            <Table>
                <TableHeader title="Karta graficzna" />
                <tbody>
                
                    <TableRow  value={config.kartygraficzne} />
                </tbody>
            </Table>
            
        )}
  
          
    </div>
    </>
  );
};

export default Config;
