const Input = ({name, text, value, onChange, type }) => {

    return (
        <>
        {type==="text" && (
            <div className="w-full md:w-1/2 px-3 md:mb-0">
            <div className="w-full flex border red-500 flex-row"> 
                <div className="w-full px-3 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
                    {text}
                </label>
                <input  type="text" 
                        name={name}
                        required
                        value={value} 
                        onChange={onChange}
                        className={"pl-2 z-0 focus:shadow focus:outline-none w-60"} 
                        />
                <p className="text-red-500 text-xs italic"></p>
                </div>
            </div>
            </div>        
        )}
        {type==="submit" && (
             <div className="w-full md:w-1/2 px-3 md:mb-0">
             <div className="w-full flex border red-500 flex-row">
               <div className="w-full px-3 md:mb-0">
                 <input
                   type="submit"
                   value={value}
                   className="bg-sky-500 hover:bg-sky-400 text-grey-darkest font-bold py-1 px-2  inline-flex items-center text-white"
                 />
     
                 <p className="text-red-500 text-xs italic"></p>
               </div>
             </div>
           </div>
        )}
           
        </>
    )
}

export default Input;