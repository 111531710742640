const InfoClone = () => {
    return (
        <div className="container mx-auto">
        <div className="font-bold">Przywracanie fabrycznej zawartości dysku.</div>
        <div className="italic">W celu przywrócenia fabrycznej zawartości twardego dysku wykonaj następujące czynności:</div>
        <div className="italic">
            <ul>
                <li> - sformatuj przenośną pamięć USB o wielkości odpowiedniej dla wielkości obrazu</li>
                <li> - pobierz plik ZIP odpowiedni dla swojego komputera</li>
                <li> - wypakuj zawartość pliku ZIP na czysty pendrive USB</li>
                <li> - uruchom komputer i ustaw start z UEFI USB</li>
                <li> - oprogramowanie CloneZilla wypakuje oryginalną zawartość na dysk Twojego komputera</li>
                <li> - po zakończeniu operacji wyjmij pendrive USB i zrestartuj komputer</li>
            </ul>
        </div>
    </div>
    )
}

export default InfoClone;