
const Thanks = ({numerZlecenia, sn, model, errorDetails, status}) => {

    return (
        <>
        {status && (
        <div className="bg-red-100 border-t-4 border-red-500 mt-6 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
        <div className="flex">
            <div className="py-1">
                <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
            </div>
            <div>
                <p className="font-bold">Błąd...</p>
                <p className="text-sm">Spróbuj ponownie za kilka chwil lub skontaktuj się z nami telefonicznie</p>
            </div>
        </div>
    </div>  
        )}


        {!status && errorDetails !== "" && (
                <div className="bg-red-100 border-t-4 border-red-500 mt-6 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
                <div className="flex">
                    <div className="py-1">
                        <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                    </div>
                    <div>
                        <p className="font-bold">Błąd zgłoszenia...</p>
                        <p className="text-sm">{errorDetails}</p>
                    </div>
                </div>
            </div>  
        )}


        {!status && numerZlecenia !=="" && (
        <div className="bg-sky-100 border-t-4 border-sky-500 mt-6 rounded-b text-sky-900 px-4 py-3 shadow-md" role="alert">
        <div className="flex">
            <div className="py-1">
                <svg className="fill-current h-6 w-6 text-sky-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
            </div>
            <div>
                <p className="font-bold">{model} o numerze seryjnym {sn} został zgłoszony do naprawy</p>
                <p className="text-sm">Poinformujemy Cię o szczegółach za pośrednictwem wiadomości email.</p>
                <p className="text-sm">Numer zgłoszenia to {numerZlecenia}.</p>
            </div>
        </div>
    </div>
        )}

        {!status && errorDetails === "" && numerZlecenia ==="" && (
                <div className="bg-sky-100 border-t-4 border-sky-500 mt-6 rounded-b text-sky-900 px-4 py-3 shadow-md" role="alert">
                <div className="flex">
                    <div className="py-1">
                        <svg className="fill-current h-6 w-6 text-sky-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                    </div>
                    <div>
                        <p className="font-bold">{model} o numerze seryjnym {sn} został zgłoszony do naprawy</p>
                        <p className="text-sm">Poinformujemy Cię o szczegółach za pośrednictwem wiadomości email.</p>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}


export default Thanks;