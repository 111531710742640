const ProgressBar = ({percent}) => {

    return (
        <div className="w-full bg-gray-300  h-6">
            <div className="bg-sky-500 h-6 " style={{width: `${percent}%` }}></div>
        </div>
    )
}

export default ProgressBar;

