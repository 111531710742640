import SubTitle from "Theme/SubTitle";
import ProgressBar from "Theme/ProgressBar";

const Link = ({ title, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="bg-gray-300 w-full py-4 mb-4 text-center hover:bg-gray-400 text-grey-darkest font-bold py-1 px-4  block"
  >
    {title}
  </a>
);

const ButtonService = ({onClick, text}) => (
  <button className="bg-gray-300 w-full py-4 mb-4 text-center hover:bg-gray-400 text-grey-darkest font-bold py-1 px-4  block" 
  onClick={onClick}>{text}</button>
) 

const Warranty = ({ computer, computer: { gwdata } , setReport, setOrderParts, reportToSupport}) => {
  const handleReportClick = (event) => {
    event.preventDefault();
    setReport(true)
  };

  const handleOrderPartsClick = (event) => {
    event.preventDefault();
    setOrderParts(true)
  }
  return (
    <div className="lg:grid lg:grid-cols-2 lg:gap-12">
      {computer && gwdata && (
        <>
          <div>
            <SubTitle>
              Okres gwarancji: {gwdata.pd_GwarancjaMies}{" "}
              {gwdata.pd_GwarancjaMies === 24 ? "miesięce" : "miesięcy"}
            </SubTitle>
            <p className="mb-10">pozostało {gwdata.remained} dni</p>
            <ProgressBar percent={gwdata.percent} />
            <div className="flex justify-between">
              <span>{gwdata.gwStart}</span>
              <span>{gwdata.gwDate}</span>
            </div>
          </div>

          <div>
            {computer.gw ? (
              <Link
                href={computer.gw}
                title="Pobierz kartę gwarancyjną w PDF"
              />
            ) : null}
            {computer.declaration ? (
              <Link
                href={computer.declaration}
                title="Pobierz deklarację zgodności w PDF"
              />
            ) : null}
            {computer.erp ? (
              <Link href={computer.erp} title="Pobierz wynik badań ERP w PDF" />
            ) : null}
           
            <ButtonService onClick={handleReportClick} text={'Zgłoś komputer do naprawy gwarancyjnej'}/>

            <ButtonService onClick={handleOrderPartsClick} text={'Zamów część zamienną'}/>
            
          </div>
        </>
      )}
    </div>
  );
};

export default Warranty;
