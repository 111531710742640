import { Outlet } from "react-router-dom";

const Portal = ({children}) => {

    return (
        <>
            <h1>Navbar</h1>
            <>
                <Outlet />
            </>
        </>
    )
}

export default Portal;