import { useState } from "react";
import SubTitle from "Theme/SubTitle";
import DriversTable from "./partials/DriversTable";
import TypeSelect from "./partials/TypeSelect";

const Drivers = ({computer}) => {
  const [type, setType] = useState("all");

  return (
    <>
      <SubTitle>Sterowniki</SubTitle>

      <TypeSelect value={type} onChange={setType} />
      <DriversTable files={computer.files} type={type} />
    </>
  );
};

export default Drivers;
