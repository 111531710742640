const InfoOther = () => {
    return(
        <div className="container mx-auto">
        <div className="font-bold">Przywracanie fabrycznej zawartości dysku.</div>
        <div className="italic">W celu przywrócenia fabrycznej zawartości twardego dysku wykonaj następujące czynności:</div>
        <div className="italic">
            inny format
        </div>
    </div>
    ) 
}

export default InfoOther;