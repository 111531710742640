import InfoModal from "./InfoModal";

const ImageTable = ({ files }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Opis
            </th>
            <th scope="col" className="px-6 py-3">
              Typ
            </th>
            <th scope="col" className="px-6 py-3">
              Wielkość pliku
            </th>
            <th scope="col" className="px-6 py-3"></th>
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {files &&
            files.map((file) => (
              <tr key={file.id} className="bg-white border-b  dark:border-gray-700">
               
                <td className="px-6 py-4">{file.description}</td>
                <td className="px-6 py-4">{file.description}</td>
                <td className="px-6 py-4">{file.filesize}</td>
                <td className="px-6 py-4 text-right">
                <InfoModal file={file.description}>Instrukcja</InfoModal>
                </td>
                <td className="px-6 py-4 text-right">
                  <a
                    href={`https://files-support.ntt.pl/${file.filename}`}
                    className="font-medium text-sky-500  hover:underline"
                  >
                    Pobierz
                  </a>
                </td>
              </tr>
            ))}

          {files.length === 0 && (
            <tr className="bg-white border-b  dark:border-gray-700">
              <td className="px-6 py-4" colSpan={99}>
                Brak plików
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ImageTable;
