import { useState } from "react";
import Modal from "react-modal";
import InfoClone from "./InfoClone";
import InfoOther from "./InfoOther";

Modal.setAppElement("#root");

const App = ({ file }) => {

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="App">
      <button onClick={toggleModal}>Instrukcja</button>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="Instrukcja"
      >
        {file === "Clonezilla Image" ? <InfoClone /> : <InfoOther/> }

        <button className="absolute top-0 right-0 h-120 w-120 bg-transparent hover:bg-sky-400 text-sky-400 font-semibold hover:text-white py-1 px-2 border border-sky-400 hover:border-transparent m-2" onClick={toggleModal}>Zamknij okno</button>
      </Modal>
    </div>
  );
}


export default App;