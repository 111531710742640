import { useEffect, useState } from "react";

const DriversTable = ({ files, type }) => {
  const [filtered, setFiltered] = useState([]);

  const filter = (f, t) => {
    if (!f) return [];
    if (t === "all") {
      return f;
    } else {
      return f.filter((f) => f.file_type === t);
    }
  };

  useEffect(() => {
    setFiltered(filter(files, type));
  }, [files, type]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Nazwa
            </th>
            <th scope="col" className="px-6 py-3">
              Typ
            </th>
            <th scope="col" className="px-6 py-3">
              System operacyjny
            </th>
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {filtered &&
            filtered.map((file) => (
              <tr key={file.url}className="bg-white border-b  dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                >
                  {file.name} <br />
                  <small>Wersja: {file.version}</small>
                </th>
                <td className="px-6 py-4">{file.file_type}</td>
                <td className="px-6 py-4">{file.operating_system}</td>
                <td className="px-6 py-4 text-right">
                  <a
                    href={file.url}
                    className="font-medium text-sky-500  hover:underline"
                  >
                    Pobierz
                  </a>
                </td>
              </tr>
            ))}

          {filtered.length===0 && (
            <tr className="bg-white border-b  dark:border-gray-700">
              <td className="px-6 py-4" colSpan={99}>Brak plików</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DriversTable;
