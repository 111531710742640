import Label from "./Label";

const TypeSelect = ({value, onChange}) => {
  return (
    <>
      <Label>Typ plików</Label>

      <select
        id="driver-type"
        className="mb-4 text-black text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4" 
        value={value}
        onChange={({target}) => onChange(target.value)}
      >
        <option value="all">Wszystkie</option>
        <option value="Audio">Audio</option>
        <option value="Bios">Bios</option>
        <option value="Chipset">Chipset</option>
        <option value="Dokumenty">Dokumenty</option>
        <option value="Inne">Inne</option>
        <option value="Karta graficzna">Karta graficzna</option>
        <option value="Lan">Lan</option>
        <option value="Raid">Raid</option>
      </select>
    </>
  );
};

export default TypeSelect;