import SubTitle from "Theme/SubTitle";
import Input from "Theme/Input";
import {useState, useEffect } from "react";
import axios from "axios";
import Thanks from "./Thanks";

const serviceForm = {
  serialnumber: "",
  symkar: "",
  computername: "",
  name:"",
  contactperson:"",
  phone:"",
  email:"",
  street:"",
  postcode:"",
  city:"",
  description:""
}


const ReportToService = ({computer}) => {

  const [inputs, setInputs] = useState(serviceForm);
  const [showThanks, setThanks] = useState(false);
  const [failedSend, setFailedSend] = useState(true);
  const [numerZlecenia, setNumerZlecenia] = useState("")
  const [errorDetails, setErrorDetails] = useState("");
  
  useEffect(()=> {
    setInputs(values => ({...values, serialnumber:computer.sn}));
    setInputs(values => ({...values, computername:computer.name}));
    setInputs(values => ({...values, symkar:computer.symkar}));
  }, [computer])


  const sendToService = () => {
    axios
      .post(`https://support.ntt.pl/proxy.php`, {
        method: "send",
        data: inputs
       
      })
      .then(({ data }) => {
        if (data.data.status==="ERROR") {
          setErrorDetails(data.data.error)
          setThanks(true);
          setFailedSend(false);
        } else {
          setThanks(true);
          setFailedSend(false);
          setNumerZlecenia(data.data.numerZlecenia);
        }

      })
      .catch((error) => {
        console.error(error);
        setFailedSend(false);
      });
  };

  const handleSendToService = (event) => {
    event.preventDefault();
    
    //tymczasowo pomijam walidacje
    //sprawdzamy czy nie jest pusty
    sendToService();
    return;
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  return (
        <>
          <div>
            <SubTitle>
              Zgłoś komputer do naprawy: 
            </SubTitle>

          </div>
        {failedSend && (
          <>
          <div>
          <form className="w-full max-w-lg" onSubmit={handleSendToService}>
          <div>
            <p>Numer seryjny: {computer.sn}</p><p>Model: {computer.name}</p>
          </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <Input type="text" name="name" id="name" value={inputs.name} onChange={handleChange} text="Nazwa zgłaszającego"/>
                <Input type="text" name="contactperson" id="contactperson" value={inputs.contactperson} onChange={handleChange} text="Osoba kontaktowa"/>
                <Input type="text" name="email" id="email" value={inputs.email} onChange={handleChange} text="Adres email"/>
                <Input type="text" name="phone" id="phone" value={inputs.phone} onChange={handleChange} text="Numer telefonu"/>
                <Input type="text" name="street" id="street" value={inputs.street} onChange={handleChange} text="Ulica"/>
                <Input type="text" name="postcode" id="postcode" value={inputs.postcode} onChange={handleChange} text="Kod pocztowy"/>
                <Input type="text" name="city" id="city" value={inputs.city} onChange={handleChange} text="Miejscowość"/>
                <Input type="text" name="description" id="description" value={inputs.description} onChange={handleChange} text="Opis usterki"/>
            </div>
            <Input type="submit" value="Wyślij zgłoszenie" />
        </form>
          </div>
                  <div>
                  
                </div>
                </>
          )}

        <div>
          {showThanks && (
            <Thanks errorDetails={errorDetails} numerZlecenia={numerZlecenia} sn={computer.sn} model={computer.name} status={failedSend} />
          )}
        </div>
        </>

  );
};

export default ReportToService;
