import SubTitle from "Theme/SubTitle";
import InputWithButton from "Theme/InputWithButton";
import { BsSearch } from "react-icons/bs";

const SnFinder = ({ sn, setSn, callback, error }) => {
  const send = (event) => {
    event.preventDefault();

    callback(sn);
  };

  return (
    <>
      <SubTitle>Wprowadź numer seryjny swojego komputera</SubTitle>
      <p>znajdziesz go w karcie gwarancyjnej lub na komputerze</p>
      <form onSubmit={send}>
        <InputWithButton
          text="Wyszukaj"
          value={sn}
          onChange={setSn}
          Icon={<BsSearch />}
        />
        {error && (
          <div
            className="bg-red-500 text-white text-sm font-bold mt-2 px-2 py-2"
            role="alert"
          >
            <p>Nie znaleziono komputera z podanym numerem seryjnym</p>
          </div>
        )}
      </form>
    </>
  );
};

export default SnFinder;
