import React, { useState, useEffect } from "react";
import SubTitle from "Theme/SubTitle";
import axios from "axios";
import Spinner from "Theme/Spinner";
import LinkButton from "Theme/LinkButton";
import Button from "Theme/Button";

const isNumeric = (value) => /^-?\d+$/.test(value);

const AutoDetector = ({ callback, setSn, setConfig }) => {
  const [loading, setLoading] = useState(true);
  const [computer, setComputer] = useState();
  const [appInstalled, setAppInstalled] = useState(false);

  const submit = (event) => {
    event.preventDefault();

    setSn(computer.sn);
    callback(computer.sn);
  };

  const simplifyConfig = (rawData) => {
    return rawData;
    return {
      manufacturer: rawData.manufacturer,
      model: rawData.model,
      uuid: rawData.uuid,
      systemoperacyjny: rawData.systemoperacyjny.split('\r\n'), // Usuwamy znaki nowej linii i łączymy w jeden string
      bios: rawData.bios.split('\n'), // Bierzemy tylko pierwszy wiersz z opisu BIOS-u
      plytaglowna: rawData.plytaglowna.split('\n'), // Bierzemy tylko pierwszy wiersz z opisu płyty głównej
      listacpu: rawData.listacpu.split('\n'), // Bierzemy pierwszy wiersz o procesorze
      listadyskow: rawData.listadyskow.split('\n'), // Bierzemy pierwszy wiersz o dysku
      pamiec: rawData.pamiec.split('\n'), // Bierzemy pierwszy wiersz o pamięci RAM
      kartysieciowe: rawData.kartysieciowe.split('\n'), // Bierzemy pierwszy wiersz o karcie sieciowej
      kartydzwiekowe: rawData.kartydzwiekowe.split('\n'), // Bierzemy pierwszy wiersz o karcie dźwiękowej
      kartygraficzne: rawData.kartygraficzne.split('\n') // Bierzemy pierwszy wiersz o karcie graficznej
    };
  };

  useEffect(() => {
    axios
      .get("http://localhost:5055/")
      .then((result) => {
        setAppInstalled(true);
        setComputer(result.data);
        if(result.data?.uuid)
        setConfig(simplifyConfig(result.data));
        setLoading(false);
        setSn(result.data.sn);
        callback(result.data.sn);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setAppInstalled(false);
        }
        setLoading(false);
      });
  }, []);

  return (
    <>
      <SubTitle>Automatyczna identyfikacja komputera</SubTitle>
      {loading === true ? (
        <Spinner size={12} />
      ) : (
        <>
          {appInstalled && computer ? (
            <>
              <p>Model: {computer.model}</p>
              <p className="pb-4">
                Numer seryjny: {isNumeric(computer.sn) ? computer.sn : "brak"}
              </p>
              {isNumeric(computer.sn) ? (
                <Button onClick={submit} text="Wybierz wykryty system" />
              ) : null}
            </>
          ) : (
            <>
              <p className="pb-4">Nie wykryto aplikacji NTT Support App</p>
              <LinkButton title="Pobierz" href="/supportapp-realsn.zip" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AutoDetector;
