

const InputWithButton = ({text, Icon, value, onChange}) => {

    return (
        <div className="w-full flex border flex-row border-sky-500 mt-4"> 
         
            <input type="text" value={value} onChange={({target}) => onChange(target.value)} className="pl-2 w-3/4 rounded-lg z-0 focus:shadow focus:outline-none" placeholder="Wpisz numer seryjny..." />
            <div className="block">
                <button className="bg-sky-500 w-full hover:bg-sky-400 text-grey-darkest font-bold py-1 px-2  inline-flex items-center text-white">
                    <span className="pr-2">
                        {Icon}
                    </span>
                    
                    <span>{text}</span>
                </button>
            </div>
        </div>
    )
}

export default InputWithButton;